import "./App.css";

import { Contact, Gallery, Home } from "./scenes";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bize-ulasin" element={<Contact />} />
        <Route path="/galeri" element={<Gallery />} />
      </Routes>
    </Router>
  );
}

export default App;
