// src/theme.js or wherever you define your theme
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    // Apply the font family to all text
    fontFamily: '"Anton", sans-serif',
    // Other global styles
  },
  // Other theme settings
});

export default theme;
