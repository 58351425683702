import * as React from "react";

import { Close, DragHandle } from "@mui/icons-material";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import logo from "../assets/pd-logo.png";
import { useNavigate } from "react-router-dom";

export default function AnchorTemporaryDrawer({ onHakkimizdaClick }) {
  const navigate = useNavigate();
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw",
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginX: "1rem",
          marginTop: "1rem",
        }}
      >
        <img style={{ width: "8rem" }} src={logo} />
        <Button
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },

            width: "2rem",
            // backgroundColor: "#ff0000",
            color: "white",
            height: "3rem",
            "&:hover": {
              // backgroundColor: "#ff0000",
              borderColor: "#ff0000",
            },
            fontFamily: "Antonio",
            borderColor: "#ff0000",
            marginRight: "1rem",
          }}
          variant="outlined"
          onClick={toggleDrawer("right", false)}
        >
          <Close sx={{ color: "#ff0000" }} />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Button
          variant="stantard"
          sx={{
            height: "4rem",
            fontWeight: 200,
            fontFamily: "Antonio",
          }}
          fullWidth
          onClick={() => {
            navigate("/");
          }}
        >
          Ana Sayfa
        </Button>
        <Button
          variant="stantard"
          sx={{
            height: "4rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          fullWidth
          onClick={() => {
            navigate("/");
            toggleDrawer("right", false);
            //
            // onHakkimizdaClick();
          }}
        >
          Hakkımızda
        </Button>
        <Button
          variant="stantard"
          sx={{
            height: "4rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          fullWidth
          onClick={() => navigate("/galeri")}
        >
          Galerİ
        </Button>
        <Button
          variant="stantard"
          sx={{
            height: "4rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          fullWidth
          onClick={() => navigate("/bize-ulasin")}
        >
          İLetİŞİM
        </Button>
        <Button
          sx={{
            width: "70%",
            backgroundColor: "#ff0000",
            color: "white",
            height: "4rem",
            marginTop: "2rem",
            "&:hover": {
              backgroundColor: "#ff0000",
            },
            fontFamily: "Antonio",
          }}
          //   fullWidth
        >
          MENÜ
        </Button>
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Button
          sx={{
            display: { lg: "none", md: "flex", sm: "flex", xs: "flex" },

            width: "2rem",
            color: "white",
            height: "3rem",
            "&:hover": {
              borderColor: "#ff0000",
            },
            fontFamily: "Antonio",
            borderColor: "#ff0000",
            marginRight: "1rem",
          }}
          variant="outlined"
          onClick={toggleDrawer("right", true)}
        >
          <DragHandle sx={{ color: "#ff0000" }} />
        </Button>
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
