import { Box, Button } from "@mui/material";
import {
  Drawer,
  FirstPage,
  FouthPage,
  Header,
  SecondPage,
  ThirdPage,
} from "../components";
import { Email, Facebook, Google, LocalPhone, X } from "@mui/icons-material";

import { useRef } from "react";

export default function Home() {
  const navigate = {};
  const secondPageRef = useRef(null);

  const handleHakkimizdaClick = () => {
    if (secondPageRef.current) {
      window.scrollTo({
        top: secondPageRef.current.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box>
      <Header onHakkimizdaClick={handleHakkimizdaClick} />
      <FirstPage />
      {/* <SecondPage ref={secondPageRef} /> */}
      <ThirdPage />
      {/* <FouthPage onHakkimizdaClick={handleHakkimizdaClick} /> */}
    </Box>
  );
}
