import { Box, ImageList, ImageListItem, Typography } from "@mui/material";
import { Email, LocalPhone, LocationOn } from "@mui/icons-material";
import { FouthPage, Header } from "../components";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import kebap from "../assets/kebap.jpeg";
import pic2 from "../assets/pic2.jpeg";
import pic3 from "../assets/pic3.jpeg";
import pic4 from "../assets/pic4.jpeg";
import pic5 from "../assets/pic5.jpeg";
import pic6 from "../assets/pic6.jpeg";

export default function Contact() {
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#432f29",
            marginBottom: "2rem",
            fontSize: { lg: "6rem", md: "4rem", sm: "3rem", xs: "3rem" },
            paddingTop: "4rem",
          }}
          // variant="h1"
        >
          Fotoğraf Galerisi
        </Typography>
        <ArrowBackIosIcon
          sx={{
            fontSize: "2rem",
            // rotate 90 degrees
            transform: "rotate(270deg)",
            color: "#ff0000",
          }}
        />
        <Typography
          sx={{
            color: "#432f29",
            marginTop: "2rem",
            marginBottom: "4rem",
            paddingX: "1rem",
          }}
          variant="h6"
        >
          Özenle hazırlanmış fotoğraflarımızı inceleyebilirsiniz.
        </Typography>

        <Box
          sx={{
            height: "50rem",
            width: "100%",
            backgroundColor: "#432f29",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ImageList
            sx={{ width: "80%", height: 600 }}
            cols={3}
            rowHeight={250}
          >
            {[kebap, pic2, pic3, pic4, pic5, pic6].map((item) => (
              <ImageListItem key={item}>
                <img
                  srcSet={`${item}`}
                  src={`${item}`}
                  loading="lazy"
                  style={{
                    objectFit: "cover",
                    height: 250,
                    width: 400,
                  }}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </Box>
      <FouthPage />
    </Box>
  );
}
