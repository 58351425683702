import { Box, Grid } from "@mui/material";

import kebap from "../assets/kebap.jpeg";
import pic2 from "../assets/pic2.jpeg";
import pic3 from "../assets/pic3.jpeg";
import pic4 from "../assets/pic4.jpeg";
import pic5 from "../assets/pic5.jpeg";
import pic6 from "../assets/pic6.jpeg";

export default function ThirdPage() {
  return (
    <Box
      sx={{
        // height: "20rem",
        backgroundColor: "#ff0000",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          margin: 4,
          marginY: 8,
        }}
        // sx={{
        //   width: "80%",
        //   display: "flex",
        //   flexDirection: {
        //     lg: "row",
        //     md: "column",
        //     sm: "column",
        //     xs: "column",
        //   },
        //   justifyContent: "space-around",
        //   alignItems: "center",
        //   margin: "8rem",
        // }}
      >
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={pic2}
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={pic3}
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={pic5}
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={pic6}
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={kebap}
          />
        </Grid>
        <Grid item sx={{ textAlign: "center" }} xs={6} sm={6} md={4} lg={2}>
          <img
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "0rem",
              objectFit: "cover",
            }}
            src={pic4}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
