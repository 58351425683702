import { Box, Button } from "@mui/material";

import { DragHandle } from "@mui/icons-material";
import { Drawer } from "../components";
import logo from "../assets/pd-logo.png";
import { useNavigate } from "react-router-dom";

export default function Header({ onHakkimizdaClick }) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: {
          lg: "space-around",
          md: "space-between",
          sm: "space-between",
          xs: "space-between",
        },
        width: "100%",
        marginBottom: {
          lg: "0rem",
          md: "0rem",
          sm: "0rem",
          xs: "0rem",
        },
      }}
    >
      <Box
        sx={{
          marginLeft: "1rem",
        }}
        onClick={() => navigate("/")}
      >
        <img style={{ width: "10rem" }} src={logo} />
      </Box>
      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            color: "#ff0000",
          }}
          onClick={() => navigate("/")}
        >
          Ana Sayfa
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            color: "#ff0000",

            fontFamily: "Antonio",
          }}
          onClick={onHakkimizdaClick}
        >
          Hakkımızda
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            color: "#ff0000",
          }}
          onClick={() => navigate("/galeri")}
        >
          Galerİ
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            color: "#ff0000",
          }}
          onClick={() => navigate("/bize-ulasin")}
        >
          İLetİŞİM
        </Button> */}
      </Box>
      <Box
        sx={{
          // width: "12rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            display: { lg: "flex", md: "none", sm: "none", xs: "none" },

            width: "6rem",
            backgroundColor: "#ff0000",
            color: "white",
            height: "2rem",
            "&:hover": {
              backgroundColor: "#ff0000",
            },
            fontFamily: "Antonio",
          }}
          // redirect to 
          //https://menu.pionpos.com/?customerId=park-pide-doner&branchId=park-pide-doner-1
          onClick={() =>
            (window.location.href =
              "https://menu.pionpos.com/?customerId=park-pide-doner&branchId=park-pide-doner-1")
          }
        >
          MENÜ
        </Button>

        <Drawer onHakkimizdaClick={onHakkimizdaClick} />
      </Box>
    </Box>
  );
}
