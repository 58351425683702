import { Box, Button } from "@mui/material";
import { Email, LocalPhone, LocationOn } from "@mui/icons-material";

import { Typography } from "@mui/material";
import doner from "../assets/doner.webp";
import kebap from "../assets/kebap.jpeg";
import { motion } from "framer-motion";

const boxVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: { y: 0, opacity: 1 },
};
const locationLink = "https://maps.app.goo.gl/SSMccfYBeks39Yfj9";

export default function FirstPage() {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#f4f2eb",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { lg: "80%", md: "100%", sm: "100%", xs: "100%" },
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
          justifyContent: "center",
          alignItems: "center",
          paddingTop: { lg: "6rem", md: "6rem", sm: "6rem", xs: "6rem" },
        }}
      >
        <Box
          sx={{
            width: { lg: "33%", md: "96%", sm: "96%", xs: "96%" },

            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Typography
            sx={{
              fontSize: "3.4rem",
              color: "#432f29",
              lineHeight: "4.4rem",
            }}
          >
            PARK DÖNER
            <br />
            damak hafızanızı tazeliyoruz
          </Typography>
          <Typography
            sx={{
              fontSize: "2rem",
              color: "#432f29",
              //   lineHeight: "3.4rem",
              fontWeight: 400,
              letterSpacing: "0.2rem",
              fontFamily: "Antonio",
              paddingTop: "2rem",
            }}
          >
            geleneksel lezzetler yüksek standartlar
          </Typography>
          <Button
            sx={{
              width: "12rem",
              backgroundColor: "#ff0000",
              color: "white",
              fontSize: "2rem",
              height: "4rem",
              "&:hover": {
                backgroundColor: "#ff0000",
              },
              marginTop: "4rem",
            }}
            onClick={() =>
              (window.location.href =
                "https://menu.pionpos.com/?customerId=park-pide-doner&branchId=park-pide-doner-1")
            }
          >
            MENÜ
          </Button>
        </Box>
        <Box
          sx={{
            width: { lg: "67%", md: "96%", sm: "96%", xs: "96%" },
            marginTop: { lg: "0", md: "2rem", sm: "2rem", xs: "2rem" },
          }}
        >
          <img
            src={doner}
            style={{
              borderRadius: "1.4rem",
              width: "100%",
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: "80%",
          display: "flex",
          flexDirection: {
            lg: "row",
            md: "column",
            sm: "column",
            xs: "column",
          },
          justifyContent: "space-around",
          alignItems: "center",
          paddingTop: {
            lg: "10rem",
            md: "4rem",
            sm: "4rem",
            xs: "4rem",
          },
          marginBottom: "4rem",
        }}
      >
        <motion.div
          variants={boxVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.2, ease: "easeIn" }}
          viewport={{ once: false }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginY: { lg: 0, md: "2rem", sm: "2rem", xs: "2rem" },
            }}
            onClick={() =>
              // go to location link
              window.open(locationLink, "_blank")
            }
          >
            <LocationOn
              sx={{
                fontSize: "2.4rem",
                marginBottom: "0.8rem",
                color: "#ff0000",
              }}
            />
            <Typography sx={{ color: "#ff0000", fontSize: "2rem" }}>
              İstanbul, Merdivenköy
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          variants={boxVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.2, ease: "easeIn" }}
          viewport={{ once: false }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginY: { lg: 0, md: "2rem", sm: "2rem", xs: "2rem" },
            }}
            onClick={() => {
              // const whatsappUrl = `https://wa.me/${+905372653848}?text=${encodeURIComponent(
              //   "Merhaba"
              // )}`;
              // window.open(whatsappUrl, "_blank", "noopener,noreferrer");
            }}
          >
            <LocalPhone
              sx={{
                fontSize: "2.4rem",
                marginBottom: "0.8rem",
                color: "#ff0000",
              }}
            />
            <Typography sx={{ color: "#ff0000", fontSize: "2rem" }}>
              0216 360 70 48
            </Typography>
          </Box>
        </motion.div>
        <motion.div
          variants={boxVariants}
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 0.2, ease: "easeIn" }}
          viewport={{ once: false }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginY: { lg: 0, md: "2rem", sm: "2rem", xs: "2rem" },
            }}
            onClick={() => {
              window.open("mailto:info@parkdoner.com", "_blank");
            }}
          >
            <Email
              sx={{
                fontSize: "2.4rem",
                marginBottom: "0.8rem",
                color: "#ff0000",
              }}
            />
            <Typography sx={{ color: "#ff0000", fontSize: "2rem" }}>
              info@parkdoner.com
            </Typography>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
}
