import { Box, Button } from "@mui/material";
import { Email, LocalPhone } from "@mui/icons-material";

import logo from "../assets/pd-logo.png";
import { useNavigate } from "react-router-dom";

export default function FourthPage({ onHakkimizdaClick }) {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "space-aroun",
        justifyContent: "space-around",
        paddingY: "2rem",
        paddingX: "0.5rem",
      }}
    >
      <Box>
        <img style={{ width: "10rem" }} src={logo} />
      </Box>
      <Box
        sx={{
          display: { lg: "flex", md: "none", sm: "none", xs: "none" },
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontWeight: 200,
            fontFamily: "Antonio",
          }}
          onClick={() => navigate("/")}
        >
          Ana Sayfa
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          onClick={() => {
            navigate("/");
            onHakkimizdaClick && onHakkimizdaClick();
          }}
        >
          Hakkımızda
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          onClick={() => navigate("/galeri")}
        >
          Galerİ
        </Button>
        <Button
          variant="stantard"
          sx={{
            width: "12rem",
            height: "2rem",
            fontFamily: "Antonio",
            fontWeight: 200,
          }}
          onClick={() => {
            navigate("/bize-ulasin");
          }}
        >
          İLetİŞİM
        </Button>
      </Box>
      <Box
        sx={{
          width: "12rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Button
          startIcon={<LocalPhone sx={{ color: "#ff0000" }} />}
          sx={{
            height: "2rem",
            justifyContent: { lg: "start", md: "end", sm: "end", xs: "end" },
            fontFamily: "Antonio",
            color: "black",
            fontWeight: 200,
          }}
          fullWidth
          onClick={() => {
            const whatsappUrl = `https://wa.me/${+905372653848}?text=${encodeURIComponent(
              "Merhaba"
            )}`;
            window.open(whatsappUrl, "_blank", "noopener,noreferrer");
          }}
        >
          +90 000 000 00 00
        </Button>
        <Button
          sx={{
            height: "2rem",
            justifyContent: { lg: "start", md: "end", sm: "end", xs: "end" },
            fontFamily: "Antonio",
            textTransform: "none",
            color: "black",
            fontWeight: 200,
          }}
          startIcon={<Email sx={{ color: "#ff0000" }} />}
          fullWidth
          onClick={() => {
            window.open("mailto:info@parkdoner.com", "_blank");
          }}
        >
          info@parkdoner.com
        </Button>
      </Box>
    </Box>
  );
}
