import { Box, Button, Typography } from "@mui/material";
import { Email, LocalPhone, LocationOn } from "@mui/icons-material";
import { FouthPage, Header } from "../components";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

export default function Contact() {
  const locationLink = "https://maps.app.goo.gl/SSMccfYBeks39Yfj9";
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#432f29",
            marginBottom: "2rem",
            fontSize: { lg: "6rem", md: "4rem", sm: "3rem", xs: "3rem" },
            paddingTop: "4rem",
            textAlign: "center",
          }}
          // variant="h1"
        >
          Talepleriniz için bize ulaşın
        </Typography>
        <ArrowBackIosIcon
          sx={{
            fontSize: "2rem",
            // rotate 90 degrees
            transform: "rotate(270deg)",
            color: "#ff0000",
          }}
        />
        <Typography
          sx={{
            color: "#432f29",
            marginTop: "2rem",
            marginBottom: "4rem",
            paddingX: "1rem",
          }}
          variant="h6"
        >
          Haftanın her günü sabah 10:00 - akşam 10:00 arası hizmetinizdeyiz.
        </Typography>
        <Button
          sx={{
            justifyContent: "start",
            fontFamily: "Antonio",
            textTransform: "none",
            color: "#ff0000",
            fontWeight: 700,
            fontSize: { lg: "3rem", md: "2rem", sm: "2rem", xs: "2rem" },
          }}
          onClick={() =>
            // go to location link
            window.open(locationLink, "_blank")
          }
          startIcon={
            <LocationOn
              sx={{
                color: "#ff0000",
                width: "48px", // Set icon width
                height: "48px", // Set icon height
              }}
            />
          }
          //   fullWidth
        >
          İstanbul, Ayazağa
        </Button>
        <Button
          sx={{
            justifyContent: "start",
            fontFamily: "Antonio",
            textTransform: "none",
            color: "#ff0000",
            fontWeight: 700,
            fontSize: { lg: "3rem", md: "2rem", sm: "2rem", xs: "2rem" },
          }}
          onClick={() => {
            const whatsappUrl = `https://wa.me/${+905372653848}?text=${encodeURIComponent(
              "Merhaba"
            )}`;
            window.open(whatsappUrl, "_blank", "noopener,noreferrer");
          }}
          startIcon={
            <LocalPhone
              sx={{
                color: "#ff0000",
                width: "48px", // Set icon width
                height: "48px", // Set icon height
              }}
            />
          }
          //   fullWidth
        >
          +90 532 000 00 00{" "}
        </Button>
        <Button
          sx={{
            justifyContent: "start",
            fontFamily: "Antonio",
            textTransform: "none",
            color: "#ff0000",
            fontWeight: 700,
            fontSize: { lg: "3rem", md: "2rem", sm: "2rem", xs: "2rem" },

            marginBottom: "4rem",
          }}
          onClick={() => {
            window.open("mailto:info@parkdoner.com", "_blank");
          }}
          startIcon={
            <Email
              sx={{
                color: "#ff0000",
                width: "48px", // Set icon width
                height: "48px", // Set icon height
              }}
            />
          }
          //   fullWidth
        >
          info@parkdoner.com
        </Button>
      </Box>
      <FouthPage />
    </Box>
  );
}
